import(/* webpackMode: "eager" */ "/app/components/animation/ButtonClickAnimation.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/AppsFlyer.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/BannerDownloadButton/BannerDownloadButtonComponentV2.module.css");
import(/* webpackMode: "eager" */ "/app/components/common/BannerNew/BannerNewComponentV2.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/CommonWithdrawalPartner/CommonWithdrawalPartnerComponentV2.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/CusotmLink/CustomLinkComponent.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/CustomImage/CustomImageComponent.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/DisclaimerComponent/DisclaimerComponent.module.css");
import(/* webpackMode: "eager" */ "/app/components/common/DownloadApp/DownloadAppComponentV2.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/FixedDownloadButton/FixedDownloadButtonV2.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/FloatingDownloadButton/FloatingDownloadButtonComponentV2.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/FloatingDownloadButton/FloatingDownloadButtonComponentWithStory.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/FloatingDownloadButton/FloatingDownloadButtonComponentWithVideo.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/Footer/FooterComponentV2.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/FooterLinks/FooterLinksSectionV2.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/Header/HeaderComponentV2.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/OfferPopup/OfferPopupComponent.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/PageFAQ/PageFAQComponentV2.module.css");
import(/* webpackMode: "eager" */ "/app/components/common/SideDrawer/SideDrawerComponent.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/Toast/ToastComponent.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/TrapeziumBackground/H2TrapeziumBG.module.css");
import(/* webpackMode: "eager" */ "/app/components/common/UserRatings/UserRatingComponentV2.module.css");
import(/* webpackMode: "eager" */ "/app/components/common/Utils/DottedGrid.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/WinzoBannerBottomHomePage/WinzoBannerBottomHomePageComponentV2.module.css");
import(/* webpackMode: "eager" */ "/app/components/common/MemberOf/MemberOfComponentV2.module.css");
import(/* webpackMode: "eager" */ "/app/components/common/WinzoUSP/WinzoUSPComponentV2.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/WinzoVideo/WinzoVideoComponentV2.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/WithdrawalPartners/WithdrawalPartnersComponentV2.module.css");
import(/* webpackMode: "eager" */ "/app/components/noJSComponents/AboutUs/AboutUsComponent.tsx");
import(/* webpackMode: "eager" */ "/app/components/noJSComponents/AboutUs/AboutUsComponentV2.tsx");
import(/* webpackMode: "eager" */ "/app/components/noJSComponents/CommonWithdrawalPartner/CommonWithdrawalPartnerComponentV2.module.css");
import(/* webpackMode: "eager" */ "/app/components/noJSComponents/HowToInstallApp/HowToInstallAppComponentV2.tsx");
import(/* webpackMode: "eager" */ "/app/components/noJSComponents/PopularGames/PopularGamesComponentV2.module.css");
import(/* webpackMode: "eager" */ "/app/components/noJSComponents/PopularGames/PopularGamesSliderDesktop.tsx");
import(/* webpackMode: "eager" */ "/app/components/noJSComponents/WinzoStoreOffer/WinzoStoreOfferComponentV2.module.css");
import(/* webpackMode: "eager" */ "/app/components/noJSComponents/winzoWinner/WinzoWinnerComponentV2.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js")